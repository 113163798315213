<template>
    <div class="pagination">
        <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            layout="prev, pager, next,jumper"
            :total="pageTotal"
            @current-change="currentChage"
            prev-text="上一页"
            next-text="下一页"
        ></el-pagination>
    </div>
</template>

<script>
export default {
    name: "Pagination",
    props: {
        pageSize: {
            // 没有显示多少条
            type: Number,
            default: 10
        },
        currentPage: {
            // 当前页
            type: Number,
            default: 1
        },
        pageTotal: {
            // 总页数
            type: Number,
            default: 1
        }
    },
    data() {
        return {};
    },
    methods: {
        currentChage(curpage) {
            this.$emit("current-change", curpage);
        }
    }
};
</script>

<style lang="less">
.pagination {
    margin: 24px 0 40px 0;
    text-align: right;
    .el-pagination {
        padding: 0;
        text-align: center;
        display: inline-block;
    }
    .btn-prev,
    .btn-next {
        padding: 0 10px;
        border-radius: 2px;
        line-height: 30px;
        background: none;
        font-size: 14px;
        color: #999;
        font-weight: normal;
        border: 1px solid #eee;
        &:hover {
            opacity: .9
        }
        &:disabled {
            background: white;
        }
    }
    .number {
        margin: 0 5px;
        padding: 0 10px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        font-size: 14px;
        color: #999;
        font-weight: 400;
        box-sizing: border-box;
        &.active + .number {
            border-left: 1px solid #d9d9d9;
        }
        &:hover {
            color: #587ddc;
        }
        &.active {
            background: #587ddc;
            color: #fff;
            border-color: #587ddc;
        }
    }
}
</style>


