<template>
    <div>
        <top-nav></top-nav>
        <header-guide :keyword="searchFields.keyword"></header-guide>
        <bread-crumb></bread-crumb>
        
        <main
            class="search-main search_main"
            v-loading="loading"
            element-loading-text="加载中"
            element-loading-spinner="el-icon-loading"
            style="background:#f5f5f5;"
        >
            <div class="container">
                <div class="employer-panel">
                    <div class="employer-hd new_employer_hd">
                        <dl class="filter-items">
                            <dt>服务标签：</dt>
                            <dd>
                                <el-checkbox-group
                                    v-model="abilityValue"
                                    @change="tagCboxChange"
                                >
                                    <el-checkbox
                                        class="el_checkbox"
                                        v-for="(item,index) in tagListData"
                                        :key="index"
                                        :label="item.name"
                                    >{{item.name}}</el-checkbox>
                                </el-checkbox-group>
                            </dd>
                        </dl>

                        <dl class="filter-items">
                            <dt>身份类型：</dt>
                            <dd>
                                <el-checkbox-group
                                    v-model="idValue"
                                    @change="idTypeEvent"
                                >
                                    <el-checkbox
                                        v-for="(item,index) in idTypeList"
                                        :key="index"
                                        :label="item.value"
                                    >{{item.labelTxt}}</el-checkbox>
                                </el-checkbox-group>
                            </dd>
                        </dl>
                    </div>

                    <div class="sort_wrap">
                        <div
                            @click="sort('id')"
                            :class="{'active': sortActive=='id'}"
                        >
                            <span icon="name">综合排序</span>

                            <span v-if="sortActive=='id'">
                                <img
                                    class="icon"
                                    v-if="sortType=='asc'"
                                    :src="$img.sort_top_hover"
                                />
                                <img
                                    class="icon"
                                    v-if="sortType=='desc'"
                                    :src="$img.sort_bottom_hover"
                                />
                            </span>
                        </div>

                        <div
                            @click="sort('create_time')"
                            :class="{'active': sortActive=='create_time'}"
                        >
                            <span icon="name">入驻时间</span>
                            <span v-if="sortActive=='create_time'">
                                <img
                                    class="icon"
                                    v-if="sortType=='asc'"
                                    :src="$img.sort_top_hover"
                                />
                                <img
                                    class="icon"
                                    v-if="sortType=='desc'"
                                    :src="$img.sort_bottom_hover"
                                />
                            </span>
                        </div>

                        <div
                            @click="sort('reward')"
                            :class="{'active': sortActive=='reward'}"
                        >
                            <span icon="name">项目酬金</span>
                            <span v-if="sortActive=='reward'">
                                <img
                                    class="icon"
                                    v-if="sortType=='asc'"
                                    :src="$img.sort_top_hover"
                                />
                                <img
                                    class="icon"
                                    v-if="sortType=='desc'"
                                    :src="$img.sort_bottom_hover"
                                />
                            </span>
                        </div>

                        <div class="search-address">
                            <span class="name"></span>
                            <el-checkbox
                                class="checkbox"
                                v-model="addressCheckbox"
                                @change="areaClear"
                            ></el-checkbox>
                            <el-cascader
                                class="cascader"
                                @change="areaChange"
                                :clearable="true"
                                v-model="areaValue"
                                :options="areaListData"
                                placeholder="请选择地址"
                            ></el-cascader>
                        </div>

                        <span class="right-count">
                            共有
                            <span v-text="geek_count"></span> 位极客
                        </span>
                    </div>

                    <div
                        class="employer-bd"
                        v-if="geekListData"
                    >
                        <ul class="employer-list">
                            <li
                                class="employer-item"
                                v-for="(geek,index) in geekListData"
                                :key="index"
                            >
                                <div class="pic-panel">
                                    <a
                                        href="javascript:void(0)"
                                        class="pic-box"
                                    >
                                        <img
                                            @click="detail(geek.uk)"
                                            :src="util.reImg(geek.avatar)"
                                            style="border-radius:0;"
                                        />
                                    </a>

                                    <div class="pic-txt-box">
                                        <div class="msg-box">
                                            <em class="age">
                                                <span style="color:#587ddc">{{ geek.age }}</span>
                                                <i :class="{'jk-icon-female': geek.sex==2, 'jk-icon-male': geek.sex==1 }"></i>
                                            </em>
                                            <div class="cert">
                                                <span :class="{'personal': geek.attestation_type==1,'studio':  geek.attestation_type==2,'firm': geek.attestation_type==3}">{{ geek.attestation_type_value }}工程极客</span>
                                                <div
                                                    class="cred-wrap"
                                                    v-if="geek.attestation_info && geek.attestation_info.credentials && geek.attestation_info.credentials.length>0"
                                                >
                                                    <span v-if="geek.attestation_info && geek.attestation_info.credentials && geek.attestation_info.credentials.length>0">证</span>
                                                    <strong>证书：{{ geek.attestation_info.credentials[0].title}}</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <a
                                            href="javascript:void(0)"
                                            class="send-msg"
                                            @click="sendMsg(geek.uk)"
                                        >发送消息</a>
                                    </div>
                                </div>

                                <div class="txt-panel">
                                    <h4 class="name">{{geek.name}}</h4>
                                    <div class="guide">
                                        <span class="add">
                                            {{geek.area_value}}
                                        </span>
                                        <div
                                            class="type _geekType_"
                                            v-if="geek.attestation_info && geek.attestation_info.ability_tags"
                                        >
                                            <span
                                                v-for="(badge,index) in geek.attestation_info.ability_tags"
                                                :key="index"
                                            >{{badge}}</span>&nbsp;
                                        </div>

                                        <div
                                            class="type _geekType_"
                                            v-else
                                        >
                                            <span></span>&nbsp;
                                        </div>
                                    </div>

                                    <ol class="msg-txt">
                                        <li>
                                            <span>完成项目：</span>
                                            <strong>{{geek.task_total}} 次</strong>
                                        </li>
                                        <li>
                                            <span>获得酬劳：</span>
                                            <strong>{{geek.reward}} 元</strong>
                                        </li>
                                        <li>
                                            <span class="_score_title">信用评分：</span>
                                            <el-rate
                                                :value="parseFloat(geek.credence)"
                                                score-template="{value}"
                                                class="_score"
                                                disabled
                                            ></el-rate>
                                            <span
                                                class="_score_num"
                                                style="color:#f59b15"
                                            >{{geek.credence}}</span>
                                        </li>
                                    </ol>
                                </div>
                            </li>
                        </ul>

                        <pagination
                            v-if="geekListData.length>0"
                            :pageSize="pageInfo.limit"
                            :currentPage="pageInfo.page"
                            :pageTotal="pageInfo.total"
                            @current-change="newPage"
                        ></pagination>
                    </div>
                </div>
                <div
                    class="employer-panel no-data-panel"
                    v-if="geekListData.length == 0"
                >
                    <div class="employer-hd no-data-hd">
                        <p>没有其他相关极客或其他极客设为隐身无法查看，去看看其他推荐极客吧~</p>
                    </div>
                </div>
            </div>
        </main>
        <global-footer></global-footer>
    </div>
</template>

<script>
import TopNav from "@/components/common/TopNav.vue";
import HeaderGuide from "@/components/common/HeaderGuide.vue";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import Pagination from "@/components/common/Pagination.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
export default {
    name: "search",
    metaInfo: {
        title: "工程极客列表 - 询龙网",
        meta: [
            {
                name: "keywords",
                content: "工程极客,工程技术项目,咨询人才,咨询团队,在家工作，众包平台,工程众包,造价工程,项目测绘,项目评估,工程勘测,工程设计,工程造价,工程监理,财务会计,税务服务,工程法务,技术承包,资料外包,造价工程师,装修设计"
            }, {
                name: "description",
                content: "找到让您放心的工程极客帮您解决工程难题"
            }
        ]
    },
    components: {
        TopNav, //头部吊顶
        HeaderGuide, //头部指南
        BreadCrumb, //面包屑导航
        Pagination, //分页
        GlobalFooter //公共底部
    },
    data() {
        return {
            loading: false,
            geekListData: "", //极客 - 列表数据
            tagListData: [], //服务标签 - 数据列表
            areaListData: [], //省市 - 数据列表
            sortActive: "id", // 排序当前选中active
            sortType: "desc", // 排序当前选中active
            abilityValue: ["全部"], //被选中的项目分类值
            idValue: [0], //身份类型值
            areaValue: [], //区域筛选值
            geek_count: 0, //筛选出来的极客数
            task_count: 0, //筛选出来的项目数
            addressCheckbox: false, //地址checkbox选中值
            searchFields: {
                keyword: "", //搜索关键字
                tags: "", //项目分类
                user_type: "", //身份类型
                area: "" //地址-
            },
            pageInfo: {
                //分页信息
                page: 1,
                limit: 20,
                total: 0
            },
            idTypeList: [
                //身份类型
                {
                    labelTxt: "全部",
                    value: 0
                },
                {
                    labelTxt: "公司",
                    value: 3
                },
                {
                    labelTxt: "工作室",
                    value: 2
                },
                {
                    labelTxt: "个人",
                    value: 1
                }
            ]
        };
    },

    created() {
        this.$nextTick(function () {
            this.getareaListData(); //获取地级市地址
            this.toggleSearch(); //搜索关键字等
        });
    },

    filters: {
        //项目分类格式化后输出
        abilityFilter: function (e) {
            if (e.name == "其他分类") {
                return e.name;
            } else {
                return e.name + "(" + e.total + ")";
            }
        }
    },

    methods: {
        //获取极客列表
        loadGeekList() {
            let _this = this;
            _this.loading = true;
            this.post(
                "/user/index/lists",
                {
                    page: _this.pageInfo.page, //分页信息
                    limit: _this.pageInfo.limit, //每条显示数
                    order: _this.sortActive, //排序
                    orderType: _this.sortType, //排序类型
                    search_fields: _this.searchFields //搜索字符串
                },
                res => {
                    _this.loading = false;
                    if (200 == res.code) {
                        this.$nextTick(function () {
                            _this.geek_count = res.data.total;
                            _this.tagListData = res.data.tag_list; //获取标签
                            _this.geekListData = res.data.list;
                            _this.pageInfo.total = parseInt(res.data.total);
                        });
                    }
                }
            );
        },

        //获取地级市区域数据
        getareaListData() {
            let _this = this;
            _this.post("/system/area/area", {}, function (res) {
                if (res.code == 200) {
                    _this.areaListData = res.data;
                }
            });
        },

        //分页点击方法
        newPage(page) {
            this.pageInfo.page = page; //切换点击的分页
            this.loadGeekList(); //加载数据
        },

        //服务标签
        tagCboxChange() {
            let _this = this;
            _this.pageInfo.page = 1; //切换到第一页
            _this.abilityValue = _this.abilityValue.filter(c => c != undefined); //去除值为undefined的值
            if (_this.abilityValue.length > 0) {
                if (
                    _this.abilityValue[_this.abilityValue.length - 1] == "全部"
                ) {
                    _this.abilityValue = ["全部"];
                    _this.searchFields.tags = "";
                } else {
                    _this.abilityValue = _this.abilityValue.filter(
                        c => c != "全部"
                    );
                    _this.searchFields.tags = _this.abilityValue.join(",");
                }
                _this.loadGeekList(); //加载极客列表
            } else {
                _this.searchFields.tags = "";
                _this.loadGeekList(); //加载极客列表
            }
        },

        //身份类型
        idTypeEvent() {
            if (this.idValue[this.idValue.length - 1] == 0) {
                this.idValue = [0];
                this.searchFields.user_type = ""; //搜索项清空
            } else {
                this.idValue = this.idValue.filter(c => c != 0); //把全部除掉
                this.searchFields.user_type = this.idValue.join(","); //赋值搜索项
            }
            this.loadGeekList();
        },

        //排序事件
        sort(e) {
            if (this.sortType == "desc") {
                this.sortType = "asc";
            } else {
                this.sortType = "desc";
            }

            switch (e) {
                case "id":
                    this.sortActive = "id";
                    break;
                case "create_time":
                    this.sortActive = "create_time";
                    break;
                case "credence":
                    this.sortActive = "credence";
                    break;
                case "reward":
                    this.sortActive = "reward";
                    break;
                default:
                    break;
            }

            this.loadGeekList(); //加载数据
        },

        //改变地址事件
        areaChange() {
            let _this = this;
            _this.addressCheckbox = true;
            _this.searchFields.area = _this.areaValue;
            _this.loadGeekList(); //加载极客列表
        },

        //清空地址选择
        areaClear() {
            if (this.areaValue != []) {
                this.areaValue = [];
                this.searchFields.area = [];
                this.loadGeekList(); //加载极客列表
            }
        },

        //极客个人主页
        detail(uk) {
            //存储当前值
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            );
            this.$router.push("/firm/index?uk=" + uk);
        },

        //发送消息
        sendMsg(e) {
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            ); //存储当前值
            this.$router.push("/user/message/sendmsg?uk=" + e);
        },

        //根据路由搜索极客还是项目
        toggleSearch() {
            //标签搜索
            if (this.$route.query.tag) {
                this.abilityValue = [this.$route.query.tag]; //标记选中项
                this.searchFields.tags = this.$route.query.tag; //赋值搜索标签
            }

            //关键字搜索
            this.searchFields.keyword = this.$route.query.keyword;
            this.loadGeekList(); //刷新数据
        }
    },

    watch: {
        $route: {
            handler: function (val, oldVal) {
                this.toggleSearch();
            },
            deep: true
        }
    }
};
</script>

<style lang="less">
@import "../../styles/search.less";
.search_main {
    .el-tabs__nav-scroll {
        width: 1180px;
        margin: 0 auto;
        height: inherit;
    }
}
._score_title {
    float: left;
}
._score {
    float: left;
    width: 100px;
    .el-rate__item {
        width: 18px !important;
        .el-rate__icon {
            font-size: 22px;
        }
    }
}

/deep/.msg-txt {
    ._score {
        width: 100px;
    }
}

._geekType_ {
    width: 100px;
    overflow: hidden;
    height: 16px;
}
.sort_wrap {
    height: 48px;
    line-height: 46px;
    margin-top: 20px;
    background: #fafafa !important;
    border: 1px solid #eae9e6;
    color: #666;
    div {
        float: left;
        display: block;
        width: 108px;
        height: 100%;
        text-align: center;
        cursor: pointer;
        border-right: 1px solid #eae9e6;
        .icon {
            vertical-align: middle;
        }
    }
    div.active {
        border-right: 1px solid #eae9e6;
        border-left: 1px solid #eae9e6;
        color: #587ddc !important;
        background: #f5f5f5;
        margin-left: -1px;
    }

    ._vline_ {
        border-right: 1px solid #eae9e6;
        width: 1px;
        height: 35px;
        margin-top: 5px;
    }
}

.new_employer_hd {
    width: 1200px;
    padding: 20px;
    padding-bottom: 10px !important;
    background: #fff;
    .el-checkbox {
        padding: 0px;
        margin: 0px;
        padding-top: 4px !important;
        margin-left: 10px;
    }
    .filter-items {
        padding: 0px;
        margin: 0px;
        border: none;
        margin-bottom: 10px;
    }
    .filter-items dd {
        padding: 0px;
        margin: 0px;
        width: 1050px !important;
        line-height: 16px;
    }
    .filter-items dt {
        width: 76px;
    }
    .el-checkbox-group {
        padding: 0px;
        margin: 0px;
    }
    .el-cascader {
        height: 27px !important;
    }
}

.el-checkbox__label {
    font-weight: normal;
}
</style>




